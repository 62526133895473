import {
    Alert,
    Card,
    Checkbox,
    Col,
    Input,
    message,
    Row,
    Slider,
    Space,
    Tooltip,
} from 'antd';
import React from 'react';
import { css } from '@emotion/css';

const urlString$default1 = url_stringify({
    url: (window.location.href + '#URL参数解码章节') as URL_String,
    params: {
        sid: 'kuaishou.account.test2',
        callback: {
            url: 'https://example.com?type=ok',
            params: {
                id: [new Date().getFullYear(), '9999'] as string[],
            },
        },
        timestamp: String(Date.now()),
    },
} as any);

const key$stringUrl = 'url-tool::stringUrl-v20230415';

const initState = {
    // 左边的原字符串
    stringUrl: localStorage[key$stringUrl] || urlString$default1,

    // 右边的 json
    jsonUrl: '',

    stringError: null as null | Error,
    jsonError: null as null | Error,

    // 空格缩进数
    jsonSpaceIndent: 8,
    // 是否对 Json URL 进行一次解码
    jsonResultDecodeURIComponent: true,
};

export class UrlTool extends React.Component<any, typeof initState> {
    static title = 'URL';

    constructor(props: any) {
        super(props);
        this.state = initState;
    }

    doString2json = () => {
        if (this.state.stringUrl.trim().length === 0) {
            this.setState({
                stringError: null,
            });
            return;
        }

        try {
            // 为了方便阅读, 下面调用一次 decodeURIComponent。
            let jsonUrl = JSON.stringify(
                url_parse(this.state.stringUrl as URL_String),
                null,
                this.state.jsonSpaceIndent,
            );
            if (this.state.jsonResultDecodeURIComponent) {
                jsonUrl = decodeURIComponent(jsonUrl);
            }
            this.setState(
                {
                    jsonUrl,
                    stringError: null,
                },
                () => {
                    // message.info('已解码。');
                },
            );
        } catch (e: any) {
            // message.error(`解码错误。${e}`);
            this.setState({
                stringError: e,
            });
            console.error(e);
        }
    };

    doJson2String = () => {
        // 解析时, 也允许输入 JavaScript 对象。
        function parse(str: string) {
            try {
                return JSON.parse(str);
            } catch (e1) {
                try {
                    return (window as any).eval(str);
                } catch (e2) {
                    throw e1;
                }
            }
        }

        if (this.state.jsonUrl.trim().length === 0) {
            this.setState({
                jsonError: null,
            });
            return;
        }

        try {
            const stringUrl = url_stringify(parse(this.state.jsonUrl));
            this.setState(
                {
                    stringUrl,
                    jsonError: null,
                },
                () => {
                    // message.info('已编码。');
                },
            );
        } catch (e: any) {
            // message.error(`编码错误。${e}`);
            this.setState({
                jsonError: e,
            });
            console.error(e);
        }
    };

    componentDidMount() {
        if (this.state.stringUrl) {
            this.doString2json();
        } else if (this.state.jsonUrl) {
            this.doJson2String();
        }
    }

    componentDidUpdate(
        prevProps: Readonly<typeof initState>,
        prevState: Readonly<typeof initState>,
        snapshot?: any,
    ) {
        const stringUrl = this.state.stringUrl;
        if (!stringUrl) {
            delete localStorage[key$stringUrl];
        } else {
            try {
                localStorage[key$stringUrl] = stringUrl;
            } catch (e) {
                console.error(e);
                localStorage.clear();
            }
        }
    }

    render() {
        return (
            <>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gap: '.618rem',
                        padding: '.618rem',
                        backgroundColor: '#f4f4f4',
                    }}
                >
                    <Card title="原 URL 字符串" bordered={false}>
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            {/*<Button onClick={this.doString2json}>解码</Button>*/}
                            <Input.TextArea
                                value={this.state.stringUrl}
                                onChange={(e) => {
                                    this.setState(
                                        { stringUrl: e.target.value },
                                        this.doString2json,
                                    );
                                }}
                                style={{ width: '100%' }}
                                rows={10}
                            />
                            {this.state.stringError && (
                                <Alert
                                    message={`${this.state.stringError.name}: ${this.state.stringError.message}`}
                                    description={this.state.stringError.stack}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Space>
                    </Card>

                    <Card
                        title={
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="">参数解码信息</div>
                                    <Tooltip title="缩进空格数">
                                        <>
                                            <Slider
                                                tooltip={{
                                                    placement: 'bottom',
                                                }}
                                                style={{ width: '10em' }}
                                                value={
                                                    this.state.jsonSpaceIndent
                                                }
                                                onChange={(e) =>
                                                    this.setState(
                                                        { jsonSpaceIndent: e },
                                                        this.doString2json,
                                                    )
                                                }
                                                min={0}
                                                max={10}
                                                marks={{ 0: 0, 4: 4, 8: 8 }}
                                            ></Slider>
                                        </>
                                    </Tooltip>
                                    <Tooltip title="解码后调用一次 DecodeURIComponent">
                                        <>
                                            <Checkbox
                                                checked={
                                                    this.state
                                                        .jsonResultDecodeURIComponent
                                                }
                                                onChange={(e) => {
                                                    this.setState(
                                                        {
                                                            jsonResultDecodeURIComponent:
                                                                e.target
                                                                    .checked,
                                                        },
                                                        this.doString2json,
                                                    );
                                                }}
                                            >
                                                {' '}
                                                Decode Once{' '}
                                            </Checkbox>
                                        </>
                                    </Tooltip>
                                </div>
                            </>
                        }
                        bordered={false}
                    >
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            {/*<Button onClick={this.doJson2String}>编码</Button>*/}
                            <Input.TextArea
                                value={this.state.jsonUrl}
                                onChange={(e) => {
                                    this.setState(
                                        { jsonUrl: e.target.value },
                                        this.doJson2String,
                                    );
                                }}
                                style={{ width: '100%' }}
                                autoSize={{ minRows: 10 }}
                            />
                            {this.state.jsonError && (
                                <Alert
                                    message={`${this.state.jsonError.name}: ${this.state.jsonError.message}`}
                                    description={this.state.jsonError.stack}
                                    type="error"
                                    showIcon
                                />
                            )}
                        </Space>
                    </Card>
                </div>
            </>
        );
    }
}

/* ###################################### URL 转码工具 ###################################### */

/**
 * 将 URL 编码为字符串
 * @param url
 */
function url_stringify(url: URL): string {
    // 处理对象, 参数非法时, 下面的代码报错
    const newUrl = new URL(typeof url === 'string' ? url : url.url);

    // 参数键值对
    const paramEntries = [] as [string, string][];

    // 遍历 params
    for (const [key, val] of Object.entries((url as any).params || {})) {
        const values = val instanceof Array ? val : [val];
        for (let val of values) {
            // 值为 undefined, 剔除
            if (val === undefined) continue;
            try {
                // 代码不报错, val 是 URL, 递归处理
                paramEntries.push([key, url_stringify(val)]);
            } catch (e) {
                // 代码报错, val 不是 URL, 确保其为字符串（容错, 例如允许参数为不规范的 number ）
                paramEntries.push([key, String(val)]);
            }
        }
    }
    new URLSearchParams(paramEntries).forEach((value, key) => {
        newUrl.searchParams.append(key, value);
    });
    return newUrl.toString();
}

// 将字符串解码成 URL
function url_parse(url: URL_String): URL {
    // 如果下面的代码发生报错, 说明入参不是 URL 。
    const url$0 = new URL(url);
    // 没有 search 部分, 直接返回
    if (url$0.search.length === 0) {
        return url;
    }
    const searchParams = new URLSearchParams(url$0.search);
    const params: Record<string, ParamValue | ParamValue[]> = {};
    for (const key of searchParams.keys()) {
        const values = [] as ParamValue[];
        for (let val of searchParams.getAll(key)) {
            try {
                // 代码不报错, val 是 URL, 递归处理
                values.push(url_parse(val as URL_String));
            } catch (e) {
                // 代码报错, val 不是 URL
                values.push(val);
            }
        }
        // 如果参数长度为 1, 剥掉数组。
        if (values.length === 1) {
            params[key] = values[0];
        } else {
            params[key] = values;
        }
    }
    url$0.search = '';
    return {
        url: url$0.toString() as URL_String,
        params,
    };
}

/**
 *  如果 url 没有任何参数, 直接用字符串表示。
 *  如果存在参数, 拆解成对象来表示。
 */
type URL = URL_String | URL_Object;

type URL_String = `${string & { 0: string }}://${string}`;

type URL_Object = {
    url: URL_String;
    params: {
        [key: string]: ParamValue | ParamValue[];
    };
};

// 参数值可能是普通的字符串, 同时也可能是 URL 。
type ParamValue = string | URL;
