import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import {
    Alert,
    Button,
    Card,
    Col,
    Divider,
    Input,
    message,
    Row,
    Space,
    Tooltip,
    Form,
    Layout,
    Radio,
    InputNumber,
    Slider,
} from 'antd';
import { svg2PngDownload } from '../qr-code-tool/utils';

const key$svgXmlText = '2023/04/16::svg-tool::svgXmlText';

function correctSvgElement(svg: SVGSVGElement) {
    svg.setAttribute(
        'viewBox',
        `0,0,${svg.getBBox().width + 2},${svg.getBBox().height + 2}`,
    );
    svg.style.width = '100%';
    svg.style.height = 'auto';
}

export const SvgTool: React.FC & { title?: string } = () => {
    const [options, setOptions] = useState<{
        width: string;
        height: string;
        scale: string;
    }>({
        width: '',
        height: '',
        scale: '4',
    });

    // 错误信息
    const [svgError, setSvgError] = useState<string>('');

    // svg 输入
    const [svgToolSvgXmlText, setSvgToolSvgXmlText] = useState<string>(
        sessionStorage[key$svgXmlText] || '',
    );
    useEffect(() => {
        try {
            sessionStorage[key$svgXmlText] = svgToolSvgXmlText;
        } catch (e) {
            console.error(e);
            sessionStorage.clear();
            message.error(
                '本地缓存已满。暂不影响业务功能，但关闭标签页后数据丢失。',
            );
        }
    }, [svgToolSvgXmlText]);

    //  svg 文本 -> svg 元素
    const [svgElement, setSvgElement] = useState<SVGSVGElement | null>(null);
    useLayoutEffect(() => {
        setSvgError('');
        setSvgElement(null);
        if (svgToolSvgXmlText.length === 0) {
            return;
        }
        try {
            const svgElement = myRef.current?.querySelector('svg') || null;
            setSvgElement(svgElement);
            if (!svgElement) {
                setSvgError('请指定 svg 文本: <svg ...');
            }
        } catch (e) {
            setSvgError(String(e));
        }
    }, [svgToolSvgXmlText]);

    const myRef = useRef<HTMLDivElement | null>(null);

    // 修正 svg 元素
    useEffect(() => {
        try {
            if (svgElement) {
                correctSvgElement(svgElement);
            }
        } catch (e) {
            setSvgError('svg 文本有误。');
        }
    }, [svgElement]);

    function check() {
        if (!svgElement) {
            message.error('svg 未就绪。');
            return;
        }
        const width = options.width.length === 0 ? null : Number(options.width);
        const height =
            options.height.length === 0 ? null : Number(options.height);
        if (options.width.length > 0 && Number.isNaN(width)) {
            message.error('width 应为数字, 或留空。');
            return;
        }
        if (options.height.length > 0 && Number.isNaN(height)) {
            message.error('height 应为数字, 或留空。');
            return;
        }

        if (width && width < 0) {
            message.error('width 不能小于 0 。');
            return;
        }

        if (height && height < 0) {
            message.error('height 不能小于 0 。');
            return;
        }

        const scale = Number(options.scale) || null;

        svg2PngDownload(svgElement, {
            width,
            height,
            scale,
        });
    }

    // async function onCopyBtnClick() {
    //     // check();
    //     // saveFileFromBlob(await imageSrc2Blob(svg2DataUrl(svgElement)), `${getFileName()}.png`);
    // }

    async function onDownloadBtnClick() {
        check();
        // saveFileFromBlob(new Blob(['<?xml version="1.0" standalone="no"?>' + new XMLSerializer().serializeToString(svgElement!)]), `${getFileName()}.svg`);
    }

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '.618rem',
                padding: '.618rem',
                backgroundColor: '#f4f4f4',
            }}
        >
            <Card
                title={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div>输入 Svg 源码</div>
                        <div style={{ fontSize: '1rem', fontWeight: 'normal' }}>
                            {<></>}
                        </div>
                    </div>
                }
            >
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <Input.TextArea
                        onChange={(e) => {
                            setSvgToolSvgXmlText(e.target.value);
                        }}
                        style={{ width: '100%' }}
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        value={svgToolSvgXmlText}
                        status={svgError ? 'error' : ''}
                        allowClear
                    />
                    <div
                        ref={myRef}
                        className={'SvgContainerDiv'}
                        dangerouslySetInnerHTML={{
                            __html: (function () {
                                const ind = svgToolSvgXmlText.indexOf('<svg');
                                if (ind !== -1) {
                                    return svgToolSvgXmlText.slice(ind);
                                }
                                return '';
                            })(),
                        }}
                    ></div>
                    {svgError && (
                        <Alert
                            message={svgError}
                            description={null}
                            type="error"
                            showIcon
                            style={{ whiteSpace: 'pre-wrap' }}
                        />
                    )}
                </Space>
            </Card>{' '}
            <Card
                title={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div>导出为图片</div>
                    </div>
                }
            >
                <Form.Item
                    label="width"
                    help={
                        <>
                            只需指定宽度或高度的一个参数，另一个参数将会自动计算得出。
                        </>
                    }
                >
                    <Input
                        value={options.width}
                        onChange={(e) => {
                            setOptions({
                                ...options,
                                width: e.target.value.trim(),
                            });
                        }}
                        status={
                            options.width.length > 0 &&
                            Number.isNaN(Number(options.width))
                                ? 'error'
                                : ''
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item label="height">
                    <Input
                        value={options.height}
                        onChange={(e) => {
                            setOptions({
                                ...options,
                                height: e.target.value.trim(),
                            });
                        }}
                        status={
                            options.height.length > 0 &&
                            Number.isNaN(Number(options.height))
                                ? 'error'
                                : ''
                        }
                        allowClear
                    />
                </Form.Item>
                <Form.Item
                    label={'scale'}
                    children={
                        <Slider
                            min={1}
                            max={10}
                            step={1}
                            onChange={(e) => {
                                setOptions({
                                    ...options,
                                    scale: String(e || ''),
                                });
                            }}
                            value={Number(options.scale)}
                            tooltip={{ open: true }}
                        />
                    }
                    help={<>缩放是无损的。</>}
                />
                <div
                    style={{
                        fontSize: '1rem',
                        fontWeight: 'normal',
                        paddingTop: '.618rem',
                    }}
                >
                    {
                        <>
                            <Space>
                                {/*<Button onClick={onCopyBtnClick}>复制</Button>*/}
                                <Button onClick={onDownloadBtnClick}>
                                    保存
                                </Button>
                            </Space>
                        </>
                    }
                </div>
            </Card>
        </div>
    );
};
SvgTool.title = 'Svg';
