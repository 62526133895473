// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

(function (exports) {
    'use strict';

    // 是否为 node 环境
    let is_node = typeof window !== 'object';
    // globalThis 的别名
    exports.w = void 0;
    const log = console.log;
    // 获取 global 或 window
    {
        let w$1;
        if (typeof globalThis === 'object') {
            w$1 = globalThis;
        } else if (typeof global === 'object') {
            w$1 = global;
        } else if (typeof window === 'object') {
            w$1 = window;
        } else if (typeof self === 'object') {
            w$1 = self;
        }
        exports.w = w$1;
    }

    exports.w.l7 = log;
    exports.w.d7 = console.dir;
    exports.w.c7 = console.clear;
    exports.w.p7 = p7;
    exports.w.w7 = w7$1;
    function p7() {
        let str = '[' + new Date().toLocaleString('zh-CN').slice(-8) + ']';
        for (let i in arguments) {
            str += ' ' + String(arguments[i]);
        }
        if (is_node) {
            log('\x1b[0;01;36m' + str + '\x1b[0m');
        } else {
            log(
                '%c' + str,
                'font-weight:bold; font-size:1.2em; background-color:#bbeeff; color:#00aaee; border-radius:0.1em;',
            );
        }
    }
    function w7$1(...args) {
        let str = '[' + new Date().toLocaleString('zh-CN').slice(-8) + ']';
        for (let i in arguments) {
            str += ' ' + String(arguments[i]);
        }
        if (is_node) {
            log('\x1b[0;01;33m' + str + '\x1b[0m');
        } else {
            log(
                '%c' + str,
                'font-weight:bold;font-size:1.2em;background-color:#ffeedd;color:#ff9911;border-radius:0.1em;',
            );
        }
    }

    /* eslint-disable */

    (function () {
        /*
         * 将字符串按照正则表达式分隔为对象元素数组。
         * @param {string} str 要分隔的字符串。
         * @param {RegExp} regExp 内部会创建新正则表达式, 而不会污染入参。入参的 lastIndex 属性也会被忽略, 如果存在 y 属性也会被忽略, 可以没有 g, s 属性。
         * @returns @returns {Array<{type: 'match' | 'nonMatch', content: string}>} 分隔后的对象元素数组。
         */
        function split(str, regExp) {
            const flags = new Set(regExp.flags.split(''));
            flags.add('g');
            flags.add('s');
            flags.delete('y');

            let source = regExp.source;
            if (source.startsWith('^')) {
                source = source.slice(1);
            }
            if (source.endsWith('$')) {
                source = source.slice(0, -1);
            }
            regExp = new RegExp(source, [...flags].join(''));

            let result = [];
            let match;
            let lastIndex = 0;
            let c = 0;
            while ((match = regExp.exec(str))) {
                if (match[0].length === 0) {
                    return result;
                }
                ++c;
                if (c > 10000) {
                    w7$1('split: 匹配次数太多了。');
                    break;
                }
                if (match.index > lastIndex) {
                    result.push({
                        type: 'nonMatch',
                        content: str.slice(lastIndex, match.index),
                    });
                }
                result.push({ type: 'match', content: match[0] });
                lastIndex = regExp.lastIndex;
            }
            if (lastIndex < str.length) {
                result.push({
                    type: 'nonMatch',
                    content: str.slice(lastIndex),
                });
            }
            return result;
        }

        // 在控制台打印对非 ascii 字符的搜索结构
        function f7c() {
            return function () {
                if (arguments.length > 0) {
                    w7$1('f7c: 入参被忽略。');
                }
                return this.f7(/[^\x00-\x7f]+/);
            };
        }

        // 在控制台打印某个值的搜索结果
        function f7() {
            // abc23abc23xyz
            // abc[%c23%c]abc[%c23%c]xyz
            return function (searchNeedle, depth) {
                if (arguments.length === 0) {
                    searchNeedle = '';
                }
                let findRes = find(this, searchNeedle, depth);
                if (findRes.length === 0) {
                    w7$1('未检索到相关信息。');
                    return this;
                }

                // 去重可以不打印的某些前缀
                let res = [];
                for (const cur of findRes.reverse()) {
                    if (!res.some((old) => old.startsWith(cur))) {
                        res.unshift(cur);
                    }
                }

                res = res.join('\n');

                // 最终要给 console.log 传的参数列表
                let args = [];

                // 如果 needle 为空字符串, 就不需要上色。
                if (searchNeedle === '') {
                    args.push(res);
                } else if (searchNeedle instanceof RegExp) {
                    // 正则表达式单独处理
                    // 拷贝一份正则表达式吧, 以免污染入参
                    searchNeedle = new RegExp(
                        searchNeedle.source,
                        searchNeedle.flags,
                    );
                    const arr = split(res, searchNeedle);
                    let content = [];
                    const color = [];
                    for (const e of arr) {
                        if (e.type === 'match') {
                            content.push(`%c${e.content}%c`);
                            color.push('color: red;', 'color: black;');
                        } else if (e.type === 'nonMatch') {
                            content.push(e.content);
                        }
                    }
                    args.push(content.join(''), ...color);
                } else {
                    // 其它情况
                    // 转换为字符串
                    searchNeedle = String(searchNeedle);

                    // 转为不区分大小写的正则表达式
                    searchNeedle = createSafeRegExpFromString(searchNeedle);

                    args.push(res);
                    args[0] = res.replaceAll(searchNeedle, '%c$&%c');
                    for (
                        let i = (args[0].length - res.length) / 4;
                        i > 0;
                        --i
                    ) {
                        args.push('color: red;', 'color: black;');
                    }

                    // 获取正则表达式, 不区分大小写
                    function createSafeRegExpFromString(string) {
                        return new RegExp(
                            string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
                            'ig',
                        );
                    }
                }
                log(...args);
                return this;
            };
        }

        // 返回一个常规对象, 如果对象是 proxy, 可能需要手动调用函数式的 real()
        function r7() {
            return real(this);
        }

        function c7() {
            if (this instanceof Function) {
                log(pc({ __proto__: this.prototype }));
            } else {
                log(pc(this));
            }
            if (this !== exports.w) {
                return this;
            }
            // 在 window 上执行 c, 应该是准备清空控制台了。
            return console.clear;
        }

        function s7() {
            let obj = this;
            if (!is_node) {
                if (obj instanceof Blob) {
                    (async function () {
                        log(
                            '[Blob size=' +
                                obj.size +
                                ', type=' +
                                obj.type +
                                ', ' +
                                Array.from(
                                    new Uint8Array(await obj.arrayBuffer()),
                                )
                                    .map((num) =>
                                        num.toString(16).padStart(2, '0'),
                                    )
                                    .join(' ') +
                                ']',
                        );
                    })();
                    return;
                }
            }
            if (obj instanceof Function) {
                obj = String(obj);
            }
            if (obj instanceof String) {
                obj = String(obj);
            }
            if (obj instanceof Number) {
                obj = Number(obj);
            }
            if (obj instanceof Boolean) {
                obj = Boolean(obj);
            }
            log(stringify(obj, null));
            return this;
        }

        function p7() {
            let obj = this;
            if (obj instanceof String) {
                obj = String(obj);
            }
            if (obj instanceof Number) {
                obj = Number(obj);
            }
            if (obj instanceof Boolean) {
                obj = Boolean(obj);
            }
            log(stringify(Object.getOwnPropertyDescriptors(obj)));
            return this;
        }

        function d7() {
            console.dir(this);
            return this;
        }

        function l7() {
            log(this);
            return this;
        }

        function t7() {
            if (this === exports.w) {
                throw `不支持在 ${exports.w[Symbol.toStringTag]} 上调用 t7 。`;
            } else {
                log(calcType(this));
            }
            return this;
        }

        [c7, s7, p7, l7, d7, t7, f7, f7c, r7].forEach((fun) => {
            Object.defineProperty(Object.prototype, fun.name, {
                get: fun,
                configurable: true,
                enumerable: false,
                set(newValue) {
                    if (this instanceof Object) {
                        Object.defineProperty(this, fun.name, {
                            value: newValue,
                            configurable: true,
                            enumerable: true,
                            writable: true,
                        });
                    }
                },
            });
        });

        function calcType(arg) {
            var primary = ['number', 'string', 'boolean', 'undefined', 'null'];
            for (var type of primary) {
                if (typeof arg === type) {
                    return type;
                }
            }
            if (arg instanceof Object) {
                var ret = {};
                for (var [key, val] of Object.entries(arg)) {
                    ret[key] = calcType(val);
                }
                var str = JSON.stringify(ret, null, 2);
                str = str.replaceAll('"', '');
                return str;
            }
            return '暂未支持的类型';
        }

        function v_func(func) {
            let str = func.toString();
            str = str.replace(/.+?(?=\()/, '');
            return 'function ' + func.name + str;
        }

        function stringify(obj, show_func = true) {
            if (obj instanceof Function && show_func) {
                return v_func(obj);
            }

            if (obj instanceof String) {
                obj = String(obj);
            }
            if (obj instanceof Number) {
                obj = Number(obj);
            }
            if (obj instanceof Boolean) {
                obj = Boolean(obj);
            }

            if (!(obj instanceof Object)) {
                return String(obj);
            }

            let o1 = obj instanceof Array ? [] : {};
            for (let key of Object.getOwnPropertyNames(obj).concat(
                Object.getOwnPropertySymbols(obj),
            )) {
                o1[String(key)] = obj[key];
            }
            let set = new Set();
            let str = JSON.stringify(
                o1,
                (key, val) => {
                    if (!(val instanceof Object)) {
                        return val;
                    }
                    if (set.has(val)) {
                        return '循环引用';
                    }
                    set.add(val);
                    if (val instanceof Function) {
                        // if (show_func) {
                        //   val = v_func(val);
                        // } else {
                        val =
                            '[Function' +
                            (val.name === ''
                                ? ' (anonymous)'
                                : ': ' + val.name) +
                            ']';
                        // }
                    }
                    return val;
                },
                2,
            );
            return str.replace(/"Symbol\((.+)\)"/g, '[Symbol($1)]');
        }

        function pc(obj) {
            if (obj === null || obj === undefined) {
                return String(obj);
            }
            let arr = [];
            for (let p = obj.__proto__; p; p = p.__proto__) {
                let funName = p.constructor.name;
                let tagName = p[Symbol.toStringTag];
                arr.push(tagName || funName);
            }
            return arr.join(' ← ');
        }

        exports.w.pc = pc;
    })();

    if (
        typeof WorkerGlobalScope === 'function' &&
        self instanceof WorkerGlobalScope
    );

    if (typeof document === 'object') {
        let style = document.createElement('style');
        style.innerHTML = `

        [r], .r{
          border:solid 1px var(--red);
        }
        [g], .g{
          border:solid 1px var(--green);
        }
        [b], .b{
          border:solid 1px var(--blue);
        }
        [o], .o{
          border:solid 1px var(--orange);
        }
        [p], .p{
          border:solid 1px var(--purple);
        }

        [bg-r], .bg-r{
          background-color: var(--red);
        }
        [bg-g], .bg-g{
          background-color: var(--green);
        }
        [bg-b], .bg-b{
          background-color: var(--blue);
        }
        [bg-o], .bg-o{
          background-color: var(--orange);
        }
        [bg-p], .bg-p{
          background-color: var(--purple);
        }

        [o-r], .o-r {
          outline: solid 2px var(--red);
          outline-offset: 4px;
        }
        [o-g], .o-g {
          outline: solid 2px var(--green);
          outline-offset: 4px;
        }
        [o-b], .o-b {
          outline: solid 2px var(--blue);
          outline-offset: 4px;
        }
        [o-o], .o-o {
          outline: solid 2px var(--orange);
          outline-offset: 4px;
        }
        [o-p], .o-p {
          outline: solid 2px var(--purple);
          outline-offset: 4px;
        }

        :root {
          /* IE 下 var 无效, 此处颜色代码仅起指导作用 */
          --black: #000000;
          --blue: #00aaee;
          --orange: #ff9911;
          --red: #ff1122;
          --green: #00bb99;
          --purple: #8844dd;
          --gray: #e4e4e4;
          --bg-black: #f2f2f2;
          --bg-blue: #cceeff;
          --bg-orange: #ffeedd;
          --bg-red: #ffe2e2;
          --bg-green: #e4ffe4;
          --bg-purple: #eeeeff;
        }
        `;
        document.head.append(style);
    }

    exports.w.assign = function assign(obj) {
        if (!(obj instanceof Object)) {
            w7$1('assign: 传入了非对象:');
            log(...arguments);
            return obj;
        }
        for (let [key, val] of Object.entries(obj)) {
            exports.w[key] = val;
        }
        return obj;
    };

    /************************************************************/
    const set$1 = new Set();
    function reportError$1() {
        w7$1(`real: 嵌套层数过多。`);
    }
    function add() {
        set$1.add(reportError$1);
    }
    let flushing$1 = false;
    function flush$1() {
        for (const fun of set$1) {
            fun();
        }
        set$1.clear();
        flushing$1 = false;
    }
    function flushNextTick$1() {
        if (flushing$1) return;
        flushing$1 = true;
        queueMicrotask(flush$1);
    }
    /************************************************************/
    // 获取对象的真实值, obj 是 Proxy 时会有用
    exports.w.real = function real(obj, depth) {
        if (!(obj instanceof Object)) {
            return obj;
        }
        if (arguments.length < 2) {
            depth = 20;
        }
        if (!depth) {
            add();
            flushNextTick$1();
            return obj;
        }
        let ret;
        if (obj instanceof Array) {
            ret = [];
        } else {
            try {
                ret = new obj.constructor();
            } catch {
                ret = {};
            }
        }
        for (let [k, v] of Object.entries(obj)) {
            ret[k] = real(v, depth - 1);
        }
        return ret;
    };

    // 记录变量的打印次数
    const map = new Map();
    // 记录变量的内存序号
    const map2 = new Map();
    let order = 0; // 序号池的当前索引
    // 无参调用的次数
    let undefinedCount = 0;
    exports.w.tag = function tag(...args) {
        if (arguments.length === 0) {
            log(`无参调用了 ${++undefinedCount} 次。`);
            return;
        }
        for (let arg of args) {
            // 调用次数
            let c;
            c = map.get(arg) || 0;
            ++c;
            map.set(arg, c);
            if (c === 1) {
                for (; `t${++order}` in exports.w; ) {}
                log(`首次记录新变量 t${order} :`, arg);
                exports.w[`t${order}`] = arg;
                map2.set(arg, order);
            } else {
                let order;
                order = map2.get(arg);
                log(`第 ${c} 次记录老变量 t${order} :`, arg);
            }
        }
        return args[0];
    };

    /************************************************************/
    const set = new Set();
    function reportError() {
        w7(`find: 嵌套层数过多。`);
    }
    let flushing = false;
    function flush() {
        for (const fun of set) {
            fun();
        }
        set.clear();
        flushing = false;
    }
    function flushNextTick() {
        if (flushing) return;
        flushing = true;
        set.add(reportError);
        queueMicrotask(flush);
    }
    /************************************************************/
    /**
     * 打印出 needle 在 obj 出现的地方（某个键名或键值）
     * @param obj 检索目标, 必须是对象, 不能是基础类型, 否则直接返回 res
     * @param needle 针
     * @param depth 当前搜索深度, 过大时, 会终止搜索
     * @param prefix 检索前缀
     * @param res 累计的搜索结果, 默认为空数组
     * @param obj2prefixes 已经检索过的对象, 不重复检索
     * @return {string[]} 返回检索到的路径信息
     */
    exports.w.find = function find(
        obj,
        needle,
        depth,
        prefix,
        res,
        obj2prefixes = new WeakMap(),
    ) {
        // 如果没有前缀, 默认为空字符串
        prefix = prefix || '';
        // 如果没有结果, 初始化为空数组
        res = res || [];
        // 当前深度, 默认为 0
        depth = depth || 0;
        // 当前层级太深了, 直接返回
        if (depth >= 10) {
            flushNextTick();
            return res;
        }
        // 如果没有 needle, 视为空字符串
        if (arguments.length < 2) {
            needle = '';
        }
        // 检索目标不是对象, 直接返回
        if (!(obj instanceof Object)) {
            return res;
        }
        {
            // 取回 prefix
            const oldPrefixes = obj2prefixes.get(obj) || new Set();
            // 已经检索过了, 直接返回
            if (oldPrefixes.has(prefix)) {
                return res;
            }
            // 记录 prefix
            oldPrefixes.add(prefix);
            obj2prefixes.set(obj, oldPrefixes);
        }
        // 遍历对象的 key 和 val
        for (let [key, val] of Object.entries(obj)) {
            // 确定当前路径
            let pre =
                prefix + (obj instanceof Array ? '[' + key + ']' : '.' + key);
            // key 或 val 命中 needle
            if (contains(key, needle) || contains(val, needle)) {
                if (canBeString(val)) {
                    let val2 = String(val);
                    if (val2.length > 256) {
                        val2 =
                            val2.slice(0, 256) +
                            `... 省略剩余 ${val2.length - 256} 个字符。`;
                    }
                    res.push(pre + ' === ' + val2);
                } else {
                    res.push(pre);
                }
            }
            // 递归搜索值
            find(val, needle, depth + 1, pre, res, obj2prefixes);
        }
        return res;
    };
    const supportedPrimaryType = [
        'boolean',
        'number',
        'string',
        'bigint',
        'undefined',
    ];
    const supportedClassType = [Date, RegExp, Symbol, String];
    // 判断值能否作为字符串
    function canBeString(val) {
        if (val === null) {
            return true;
        }
        if (supportedPrimaryType.includes(typeof val)) {
            return true;
        }
        return supportedClassType.some((__Class__) => val instanceof __Class__);
    }
    exports.w.canBeString = canBeString;
    /**
     * 判断 dst 是否包含 needle。如果 dst 或者 needle 不可转换为字符串，返回 false 。
     * @param source 应为支持转换为字符串的类型
     * @param needle 应为字符串（不区分大小写）、正则表达式（无需加 g 标识, 可以加 i 标识），或者其它可以转换为字符串的类型
     */
    function contains(source, needle) {
        if (!canBeString(source) || !canBeString(needle)) {
            return false;
        }
        source = String(source);
        if (needle instanceof RegExp) {
            return !!source.match(needle);
        } else {
            needle = String(needle);
            return source.toLowerCase().includes(needle.toLowerCase());
        }
    }
    exports.w.contains = contains;

    const ori = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function () {
        if (!this.__addEventListenerRecords) {
            this.__addEventListenerRecords = [];
        }
        arguments.remove = () => {
            this.removeEventListener.apply(this, arguments);
        };
        if (!this.__addEventListenerRecords.byName) {
            this.__addEventListenerRecords.byName = {};
            this.__addEventListenerRecords.removeAll = () => {
                this.__addEventListenerRecords?.forEach((x) => x.remove());
            };
        }
        this.__addEventListenerRecords.byName[arguments[0]] = arguments;
        this.__addEventListenerRecords.push(arguments);
        return Reflect.apply(ori, this, arguments);
    };

    exports.is_node = is_node;
    exports.log = log;
    exports.p7 = p7;
    exports.w7 = w7$1;

    return exports;
})({});
