// / <reference types="./debug-lib/debug7.d.ts" />
import './debug-lib/debug7';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);

// setInterval(() => {
//     p7('i');
//     root.render(<div>好吧<div>是你{Date.now()}</div></div>)
// }, 1000);

// (<>
//     <div>好</div>
// </>).s7;
