import React, {
    ClipboardEventHandler,
    EventHandler,
    useEffect,
    useState,
} from 'react';
import {
    Button,
    Card,
    Col,
    Image,
    Input,
    Row,
    Space,
    QRCode,
    message,
    Alert,
} from 'antd';
import jsQR from 'jsqr';

import './qr-code-tool.scoped.css';
import {
    dataURL2File,
    file2DataUrl,
    imageSrc2Blob,
    imageSrc2ImageData,
    saveFileFromBlob,
    svg2DataUrl,
    src2ImageElement,
} from './utils';
import {
    CloseCircleFilled,
    CloseOutlined,
    DownloadOutlined,
} from '@ant-design/icons';

const key$imageDataUrl = 'qr-code-tool::imageDataUrl-v20230415';

export const QrCodeTool: React.FC & { title?: string } = () => {
    // ① dataUrl, 也就是 data:image/png;base64, 开头的字符串。
    const [imgDataUrl, setImgDataUrl] = useState<string>(
        localStorage[key$imageDataUrl] || '',
    );

    // ② File 对象
    const [imgFile, setImgFile] = useState<File | null>(null);

    // ③ ImageData 对象
    const [imageData, setImageData] = useState<ImageData | null>(null);

    // ④ codeData
    const [codeData, setCodeData] = useState<string>('');

    // codeDataError
    const [codeDataError, setCodeDataError] = useState<string>('');

    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(
        null,
    );

    // ① imgDataUrl -> ② File、③ ImageData
    useEffect(() => {
        // 缓存数据
        if (imgDataUrl) {
            try {
                localStorage[key$imageDataUrl] = imgDataUrl;
            } catch (e) {
                console.error(e);
                localStorage.clear();
            }
        } else {
            delete localStorage[key$imageDataUrl];
        }

        try {
            setImgFile(dataURL2File(imgDataUrl, '图片.png'));
        } catch {
            setImageData(null);
        }

        imageSrc2ImageData(imgDataUrl).then(
            (imageData) => {
                setImageData(imageData);
            },
            (reason) => {
                setImageData(null);
            },
        );

        src2ImageElement(imgDataUrl).then(
            (imgElement) => {
                setImageElement(imgElement);
            },
            (reason) => {
                setImageElement(null);
            },
        );
    }, [imgDataUrl]);

    // ③ ImageData -> ④ codeData
    useEffect(() => {
        if (!imageData) {
            setCodeData('');
            setCodeDataError('');
            return;
        }
        const res =
            jsQR(imageData.data, imageData.width, imageData.height)?.data ?? '';
        setCodeData(res);
        if (!res) {
            setCodeDataError('识别失败。');
        }
    }, [imageData]);

    async function onPaste(
        ev: React.SyntheticEvent<HTMLDivElement, ClipboardEvent>,
    ) {
        ev.preventDefault();

        // tag(ev.nativeEvent);
        const clipboardData = ev.nativeEvent.clipboardData;
        if (!clipboardData) return;
        // tag({
        //     items: [...clipboardData.items],
        //     files: [...clipboardData.files],
        //     types: [...clipboardData.types],
        // });
        // if(1) return;
        if (clipboardData.files.length > 0) {
            // p7('files:');
            // 从剪贴板张贴图片
            const file = clipboardData.files[0];
            // tag(file);
            setImgDataUrl(await file2DataUrl(file));
        } else {
            message.info('剪贴板无图片。');
        }

        // if (clipboardData.items.length > 0) {
        //     p7('items:');
        //     const item = clipboardData.items[0];
        //     const file = item.getAsFile();
        //     const text = await new Promise<string>(res => item.getAsString(res));
        //     tag(file);
        //     tag(text);
        // }
    }

    async function download() {
        if (!imgFile) {
            message.error('图片未就绪。');
            return;
        }
        saveFileFromBlob(imgFile, imgFile.name);
    }

    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '.618rem',
                padding: '.618rem',
                backgroundColor: '#f4f4f4',
            }}
        >
            {' '}
            <Card title={'图片'}>
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <div
                        className={'clipboard'}
                        contentEditable={true}
                        onPaste={onPaste}
                        onKeyDown={(e) => {
                            if (e.key === 'v' || e.key === 'V') {
                                if (e.metaKey || e.ctrlKey) {
                                    return;
                                }
                            }
                            e.preventDefault();
                        }}
                        suppressContentEditableWarning={true}
                        style={{
                            backgroundImage: `url(${imgDataUrl})`,
                            backgroundSize: 'contain',
                        }}
                    >
                        {/*<div style={{width: '100%'}}>*/}
                        {/*<Image preview={false} src={imgDataUrl} width={'60%'}/>*/}
                        {/*</div>*/}
                        {imgDataUrl && (
                            <>
                                <div className={'top-right-btn'}>
                                    <Space size={4}>
                                        <CloseOutlined
                                            onClick={() => {
                                                setImgDataUrl('');
                                            }}
                                        />
                                        <DownloadOutlined onClick={download} />
                                    </Space>
                                </div>
                            </>
                        )}
                        <div className={'tip'}>
                            <span>Ctrl / Command + v 粘贴</span>
                        </div>
                    </div>
                    <div>
                        {imageElement &&
                            `${imageElement.naturalWidth}x${imageElement.naturalHeight}`}
                    </div>
                    {codeDataError && (
                        <Alert
                            message={codeDataError}
                            description={null}
                            type="error"
                            showIcon
                        />
                    )}
                </Space>
            </Card>
            <Card title={'链接'}>
                <Space direction={'vertical'} style={{ width: '100%' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            gap: '4px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Input.TextArea
                            onChange={(e) => {
                                setCodeData(e.target.value);
                            }}
                            style={{ width: '100%', flexGrow: '1' }}
                            autoSize={{ minRows: 8 }}
                            value={codeData}
                        />
                        <div>
                            <QRCode
                                size={187}
                                value={codeData || window.location.href}
                            />
                        </div>
                    </div>
                    {/(.+):\/\/.+/.test(codeData) && (
                        <a href={codeData} rel="noreferrer" target={'_blank'}>
                            访问链接
                        </a>
                    )}
                </Space>
            </Card>
        </div>
    );
};

QrCodeTool.title = '二维码';
