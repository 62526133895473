import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import {
    Alert,
    Button,
    Card,
    Divider,
    Input,
    message,
    Space,
    Tooltip,
} from 'antd';
import './sequence-diagram.scoped.css';

// @ts-ignore
import SequenceDiagramDiv from 'react-sequence-diagram';
import {
    imageSrc2Blob,
    saveFileFromBlob,
    svg2DataUrl,
    throttle,
    timeStamp2String,
} from '../qr-code-tool/utils';

const correct = throttle(function correct(div: HTMLDivElement) {
    const svgList = [...(div?.querySelectorAll('svg') ?? [])];
    if (svgList.length > 0) {
        const svg = svgList[0];
        svg.setAttribute(
            'viewBox',
            `0,0,${svg.getBBox().width + 20},${svg.getBBox().height + 20}`,
        );
        svg.style.width = '100%';
        svg.style.height = 'auto';
        // SequenceDiagram 有 bug, 需要在组件挂载后删除第 2 个 svg 元素。
        svgList.splice(0, 1);
    }
    svgList.map((svg) => svg.remove());
}, 10);

const key$sequenceDiagramInput =
    'sequence-diagram::sequenceDiagramInput-v20230415';

export const SequenceDiagram: React.FC & { title?: string } = () => {
    // 错误信息
    const [error, setError] = useState<string>('');

    // 输入
    const [sequenceDiagramInput, setSequenceDiagramInput] = useState<string>(
        localStorage[key$sequenceDiagramInput] || '',
    );
    useEffect(() => {
        setError('');
        try {
            localStorage[key$sequenceDiagramInput] = sequenceDiagramInput;
        } catch (e) {
            console.error(e);
            localStorage.clear();
        }
    }, [sequenceDiagramInput]);

    const myRef = useRef<SequenceDiagramDiv>();

    useLayoutEffect(() => {
        const div = myRef.current?.div;
        if (!div) {
            return;
        }
        correct(div);
    }, [myRef.current?.div]);

    useLayoutEffect(() => {
        const div = myRef.current?.div;
        if (!div) {
            return;
        }
        const mutationObserver = new MutationObserver(function (
            mutations,
            observer,
        ) {
            // tag([...arguments]);
            correct(div);
        });
        mutationObserver.observe(div, {
            subtree: true,
            childList: true,
            attributes: true,
            attributeOldValue: false,
            characterData: true,
            characterDataOldValue: false,
        });
        return function () {
            mutationObserver.disconnect();
        };
    }, [myRef.current?.div]);

    function getFileName() {
        return '时序图 ' + timeStamp2String(Date.now());
    }

    async function downloadPng() {
        const svgElement = myRef.current?.div?.firstChild;
        if (!svgElement) {
            message.error('图标未就绪。');
            return;
        }
        saveFileFromBlob(
            await imageSrc2Blob(svg2DataUrl(svgElement)),
            `${getFileName()}.png`,
        );
    }

    async function downloadSvg() {
        const svgElement = myRef.current?.div?.firstChild;
        if (!svgElement) {
            message.error('图标未就绪。');
            return;
        }
        saveFileFromBlob(
            new Blob([
                '<?xml version="1.0" standalone="no"?>' +
                    new XMLSerializer().serializeToString(svgElement!),
            ]),
            `${getFileName()}.svg`,
        );
    }

    return (
        <div>
            <div
                style={{
                    backgroundColor: '#eee',
                    padding: '8px 12px',
                    width: '100%',
                }}
            >
                <Space
                    direction={'vertical'}
                    size={12}
                    style={{ width: '100%' }}
                >
                    <Card
                        title={
                            <>
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div>时序图 SequenceDiagram</div>
                                        <div
                                            style={{
                                                fontSize: '1rem',
                                                fontWeight: 'normal',
                                            }}
                                        >
                                            {
                                                <>
                                                    <Space>
                                                        <span>下载</span>
                                                        <Button
                                                            onClick={
                                                                downloadPng
                                                            }
                                                        >
                                                            png
                                                        </Button>
                                                        <Button
                                                            onClick={
                                                                downloadSvg
                                                            }
                                                        >
                                                            svg
                                                        </Button>
                                                    </Space>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </>
                            </>
                        }
                    >
                        <Space direction={'vertical'} style={{ width: '100%' }}>
                            {/*<Image src={dataUrl} preview={false}/>*/}
                            <div
                                className={'SequenceDiagramDiv'}
                                style={{
                                    display: sequenceDiagramInput.trim()
                                        ? undefined
                                        : 'none',
                                }}
                            >
                                {
                                    <SequenceDiagramDiv
                                        ref={myRef}
                                        input={sequenceDiagramInput}
                                        options={{ theme: 'simple' }}
                                        onError={(err: any) => {
                                            if (sequenceDiagramInput) {
                                                setError(
                                                    JSON.stringify(
                                                        err,
                                                        null,
                                                        2,
                                                    ),
                                                );
                                            } else {
                                                setError('');
                                            }
                                        }}
                                    />
                                }
                            </div>
                            {error && (
                                <Alert
                                    message={error}
                                    description={null}
                                    type="error"
                                    showIcon
                                    style={{ whiteSpace: 'pre-wrap' }}
                                />
                            )}
                            <Input.TextArea
                                onChange={(e) => {
                                    setSequenceDiagramInput(e.target.value);
                                }}
                                style={{ width: '100%' }}
                                autoSize={{ minRows: 5 }}
                                value={sequenceDiagramInput}
                            />
                        </Space>
                    </Card>
                    <Card title={'相关站点'}>
                        {[
                            'https://sequencediagram.org/',
                            'https://bramp.github.io/js-sequence-diagrams/',
                        ].map((url) => {
                            return (
                                <div key={url}>
                                    <a
                                        rel="noreferrer"
                                        href={url}
                                        target={'_blank'}
                                    >
                                        {url}
                                    </a>
                                </div>
                            );
                        })}
                    </Card>
                </Space>
            </div>
        </div>
    );
};
SequenceDiagram.title = '时序图';
