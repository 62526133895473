export function dataURL2File(dataUrl: string, fileName: string) {
    const regExp = /data:(?<type>.*);base64,(?<base64>.*)/;
    const { type, base64 } = (regExp.exec(dataUrl) as any).groups;
    const bytes = window.atob(base64);
    let n = bytes.length;
    const uint8Array = new Uint8Array(n);
    while (n--) {
        uint8Array[n] = bytes.charCodeAt(n);
    }
    return new File([uint8Array], fileName, { type });
}

export async function file2DataUrl(file: File) {
    return await new Promise<string>((res) => {
        const fileReader = new FileReader();
        fileReader.onload = function (ev: ProgressEvent<FileReader>) {
            res(fileReader.result as string);
        };
        fileReader.readAsDataURL(file);
    });
}

export async function imageSrc2ImageData(src: string) {
    const { context2d, width, height } = await src2CanvasInfo(src);
    return context2d.getImageData(0, 0, width, height);
}

export async function imageSrc2Blob(
    src: string,
    type = 'image/png',
): Promise<Blob> {
    const { canvas } = await src2CanvasInfo(src);
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            function (blob) {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(
                        '无法转换为 blob, canvas BlobCallback result === null.',
                    );
                }
            },
            type,
            1,
        );
    });
}

async function src2CanvasInfo(src: string): Promise<{
    context2d: CanvasRenderingContext2D;
    width: number;
    height: number;
    canvas: HTMLCanvasElement;
}> {
    if (!src) throw new Error('src 不能为空。');
    return new Promise<Awaited<ReturnType<typeof src2CanvasInfo>>>(
        (resolve, reject) => {
            const canvas = document.createElement('canvas');
            const context2d = canvas.getContext('2d');
            if (!context2d) {
                throw new Error('canvas.getContext returns' + context2d);
            }
            const img = new Image();
            img.onload = function () {
                let { width, height } = img;
                canvas.width = width;
                canvas.height = height;
                context2d.fillStyle = '#fff';
                context2d.fillRect(0, 0, width, height);
                context2d.drawImage(img, 0, 0);
                resolve({ context2d, width, height, canvas });
            };
            img.onerror = function (event, source, lineno, colno, error) {
                reject('img.onerror! src =' + src);
            };
            img.src = src;
        },
    );
}

export async function src2ImageElement(src: string): Promise<HTMLImageElement> {
    const img = new Image();
    return new Promise<HTMLImageElement>((resolve, reject) => {
        img.onload = function () {
            resolve(img);
        };
        img.onerror = reject;
        img.src = src;
    });
}

/**
 *
 * @param svgOrSvgString svg 或 svg 的 XML 数据
 * @param type mime 类型
 */
export function svg2DataUrl(
    svgOrSvgString: SVGElement | 'string',
    type = 'image/png',
) {
    // 获取 SVG 的 XML 数据
    const svgString: string =
        typeof svgOrSvgString === 'string'
            ? svgOrSvgString
            : new XMLSerializer().serializeToString(svgOrSvgString);
    return (
        'data:image/svg+xml;base64,' +
        window.btoa(window.unescape(encodeURIComponent(svgString)))
    );
}

/**
 * 将 SVG 元素转换为 PNG 图像并下载。
 * @param {SVGElement} svgElement - 要转换的 SVG 元素。
 * @param {Object} [options] - 可选的配置对象。
 * @param {number} [options.width] - 图像的宽度（以像素为单位）。如果未指定，则使用 SVG 元素的原始宽度。
 * @param {number} [options.height] - 图像的高度（以像素为单位）。如果未指定，则使用 SVG 元素的原始高度。
 * @param {number} [options.scale=1] - 缩放比例。
 */
export function svg2PngDownload(
    svgElement: SVGSVGElement,
    options?: {
        width: number | null | undefined;
        height: number | null | undefined;
        scale: number | null | undefined;
    },
) {
    const width = options?.width;
    const height = options?.height;
    let scale = options?.scale || 1;
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d')!;
    const img = new Image();
    const svgBlob = new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8',
    });
    const url = URL.createObjectURL(svgBlob);
    img.onload = function () {
        if (width && !height) {
            scale *= width / img.width;
        } else if (height && !width) {
            scale *= height / img.height;
        }
        canvas.width = img.width * scale;
        canvas.height = img.height * scale;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        URL.revokeObjectURL(url);
        var imgDataUrl = canvas.toDataURL('image/png');
        var downloadLink = document.createElement('a');
        downloadLink.href = imgDataUrl;
        downloadLink.download = '图片.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    img.src = url;
}

export function timeStamp2String(time: number) {
    const ret = new Date(time)
        .toLocaleDateString('zh-CN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            //weekday: 'short',
        })
        .replace(/\//g, '-');
    return ret.slice(0, 10) + ' ' + ret.slice(10);
}

/**
 * 对函数进行限制, 希望大致 duration 毫秒执行一次。
 * @param func
 * @param duration
 * @param thisArg
 * @return {function(...[*]): number}
 */
export function limit(
    func: Function,
    duration: number = 1000,
    thisArg?: any,
): (...args: any) => any {
    let tid: any;
    if (thisArg) func = func.bind(thisArg);
    // 上次执行时间
    let lastExecTime = 0;
    return function (this: any, ...args: any[]) {
        let now = Date.now();
        let dis = now - lastExecTime;
        let delay;
        if (dis < duration!) {
            // 短期内被执行了, 现在不能执行, 需要推迟
            delay = duration! - dis;
        } else {
            // 上次执行已经过了很久了, 现在可以执行
            delay = 0;
        }
        if (delay < 0) delay = 0;
        clearInterval(tid);
        tid = setTimeout(() => {
            func.apply(this, args);
            lastExecTime = Date.now();
        }, delay);
        return delay;
    };
}

// 1 throttle 限制 n 毫秒内只能执行一次
// 2 debounce 推迟到 n 毫秒后再执行
// 3 limit n 毫秒内最多执行 1 次

// 节流
export function throttle<T extends Function>(
    func: T,
    duration: number = 1000,
    thisArg?: any,
): T {
    let lastExecTime = 0;
    if (thisArg) {
        func = func.bind(thisArg);
    }
    return function (this: any) {
        let now = Date.now();
        let dis = now - lastExecTime;
        if (dis < duration!) {
            // 短期内已经执行过了, 现在不再执行了
            return;
        }
        lastExecTime = now;
        func.apply(this, arguments);
    } as any;
}

// 防抖
export function debounce(
    func: Function,
    duration: number = 1000,
    thisArg?: any,
) {
    let tid: any;
    if (thisArg) {
        func = func.bind(thisArg);
    }
    return function (this: any) {
        if (tid) {
            // 如果有正在等着被执行的任务, 取消它
            clearTimeout(tid);
        }
        // 新开一个 n 毫秒后执行的任务
        tid = setTimeout(() => {
            func.apply(this, arguments);
        }, duration);
    };
}

/**
 *
 * @param xml svg 的xml内容
 */
// export const svgToPng = async (xml: string) => {
//     const base64 = window.btoa(unescape(encodeURIComponent(xml)));
//     const image64 = `data:image/svg+xml;base64,${base64}`;
//     const image = await loadImage(image64);
//     return imageToPng(image);
// };

/**
 * 把内存中的 blob 存成文件
 * @param blob 数据源
 * @param fileName 文件名
 */
export function saveFileFromBlob(blob: Blob, fileName: string) {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    // 有的浏览器可能会不认可在脚本里直接 click 没有挂载到 DOM 上的 anchor 。
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        /**
         * 不能立即就销毁, 要不然有的浏览器会下载失败;
         * 另一方面, 通过 js 直接保存的文件往往也是小尺寸, 应该不用太着急考虑内存释放。
         */
        URL.revokeObjectURL(a.href);
        a.remove();
    }, 60_000); // 1分钟后再作废这个临时链接。
}

/**
 * 计算表格（二维字符串数组）对应的 csv 文件的 Blob
 * @desc 具体的序列化规则可以在 https://datatracker.ietf.org/doc/html/rfc4180 找到
 * @param records 表格数据来源
 */
export function getCsvBlobFromTable(
    records: (string | number | null)[][],
): Blob {
    // 文件序列
    const blobParts: (Blob | string | Uint8Array)[] = [];

    // 3. BOM(Byte Order Mark)，文件编码标识。
    blobParts.push(Uint8Array.of(0xef, 0xbb, 0xbf));
    // 确定行、列索引的最大取值
    const maxR = records.length - 1;
    const maxC = Math.max(...records.map((row) => row.length)) - 1;

    for (let r = 0; r <= maxR; ++r) {
        const record = records[r];
        for (let c = 0; c <= maxC; ++c) {
            let field = String(record[c] ?? '');
            // 按照标准，csv 文件内应采用 \r\n 换行；如果数据来源是 \n 的话，现在把它转换成 \r\n；不过如果现在真正不转，Microsoft Excel 似乎也不会报错。
            field = field.replace(/(?<!\r)\n/g, '\r\n');
            // 2.5. 单元格不一定要被英文双引号包围，既然 Microsoft Excel 默认不加, 那此函数也默认不加。
            // 2.6. 如果单元格出现了回车换行符、英文双引号、英文逗号, 这个单元格序列化时就需要被英文双引号包围。
            if (
                field.includes('\n') ||
                field.includes(',') ||
                field.includes('"')
            ) {
                // 2.7. 且原单元格内的每个英文双引号在序列化时需要保存为两个。
                field = `"${field.replace(/"/g, '""')}"`;
            }
            blobParts.push(field);
            if (c !== maxC) {
                // 2.4. 某一行的最后一个单元格之后不能有英文逗号，其它单元格则必须有。
                blobParts.push(',');
            }
        }
        if (r !== maxR) {
            // 2.1. 行与行之间用回车换行符分隔;
            // 2.2. 最后一行结尾不用加。
            blobParts.push('\r\n');
        }
    }
    return new Blob(blobParts);
}

/**
 * @desc 将表单对象序列化到 Storage
 *
 * 调用此接口时, 请保证传入的对象能被 JSON.stringify 正常序列化。
 * 表单对象中的 Date 会被格式化成类似 2022-04-28T14:18:57.584Z 的字符串, 反序列化时再作为 Date 的构造函数即可。
 * 表单对象中的函数会被 stringify 函数剔除。
 *
 * @param formObject 表单对象
 * @param options {} 可选项
 *   key             可以指定存储的 key; 建议不指定, 此时存储的键名会包含 url, 这样, 调用者就不用关心具体的业务线、push类别，因为这些信息已经被编码到 url 里;
 *   permanent       设置为 true 会存储到 localStorage 里, 否则默认存到 sessionStorage 里;
 *   dateRangeKeys   序列化时不需要指定, 反序列化时才需要指定; Element-Plus 的日期的数据结构是 [Date, Date], 如果表单中有日期字段, 请指定它们。
 */
export function saveFormToStorage(
    formObject: object,
    options?: { key?: string; permanent?: boolean },
) {
    const key = options?.key ?? `${window.location.pathname}/formObject`;
    const storage: Storage =
        options?.permanent ?? false ? localStorage : sessionStorage;
    storage.setItem(key, JSON.stringify(formObject));
}

/**
 * @desc 从 Storage 反序列化出表单对象
 * @param formObject
 * @param options
 * @returns {boolean} 是否恢复成功
 */
export function fillFormFromStorage(
    formObject: object,
    options?: { key?: string; permanent?: boolean; dateRangeKeys?: string[] },
): boolean {
    const key = options?.key ?? `${window.location.pathname}/formObject`;
    const storage: Storage =
        options?.permanent ?? false ? localStorage : sessionStorage;
    const dateRangeKeys = options?.dateRangeKeys ?? [];

    const json = sessionStorage.getItem(key);

    // 此前没有存储过该表单。
    if (!json) {
        return false;
    }

    let obj;
    try {
        obj = JSON.parse(json);
        // eslint-disable-next-line no-empty
    } catch {
        // parse 报错现在不作处理, 下面会直接删除这个 storage item 。
    }

    if (!(obj instanceof Object)) {
        // 存储的数据结构有误, 现在删除它。
        storage.removeItem(key);
        return false;
    }

    // 先将日期字段恢复为 element-plus 识别的 [Date, Date] 这种类型。
    for (const dateKey of dateRangeKeys) {
        if (obj[dateKey] instanceof Array) {
            const [from, to] = obj[dateKey];
            obj[dateKey] = [new Date(from), new Date(to)];
        } else {
            // 存储的数据结构有误, 这个字段最好还是不要了吧。
            delete obj[dateKey];
        }
    }

    // 填充表单
    Object.assign(formObject, obj);

    return true;
}
