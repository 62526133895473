import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { SequenceDiagram } from './pages/sequence-diagram/sequence-diagram';
// import {SSH} from "./pages/ssh";
import { UrlTool } from './pages/url-tool/url-tool';
import { QrCodeTool } from './pages/qr-code-tool/qr-code-tool';
import { SvgTool } from './pages/svg-tool/svg-tool';
import './App.css';

export const App: React.FC = () => {
    const [activeKey, setActiveKey] = useState(localStorage.tabKey);
    useEffect(() => {
        localStorage.tabKey = activeKey;
    });

    const items = [UrlTool, QrCodeTool, SequenceDiagram, SvgTool].map(
        (Item) => ({
            label: Item.title,
            key: Item.title!,
            children: <Item />,
        }),
    );

    return (
        <>
            <Tabs
                destroyInactiveTabPane={true}
                defaultActiveKey={activeKey}
                onChange={(activeKey) => setActiveKey(activeKey)}
                activeKey={activeKey}
                centered
                items={items}
            ></Tabs>
        </>
    );
};
